import { graphql, useStaticQuery } from "gatsby"

export const useAllStrapiTeam = () => {
  return useStaticQuery(
    graphql`
      query useAllStrapiTeam {
        allStrapiTeam(filter: { publish: { eq: true } }) {
          nodes {
            slug
            name
            designation
            image {
              url
            }
            phone
            imagetransforms {
              image_Transforms
            }
            whatsapp
            brn_number
            negotiator_id
            strapi_id
          }
        }
      }
    `
  )
}
