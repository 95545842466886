import loadable from "@loadable/component"
import { Link } from "gatsby"
import React, { useEffect, useState } from "react"
import { Modal } from "react-bootstrap"
import BookAViewingFields from "../../../static/forms/book_a_viewing_form.json"
import CareerFormFields from "../../../static/forms/careers_form.json"
import { PageLinks } from "../../common/site/page-static-links"
import { useAllStrapiTeam } from "../../hooks/use-all-strapiteam"
import { getWhatsAppURL } from "../../lib/utils"
import "./assets/styles/_index.scss"

const DefaultForm = loadable(() => import("../forms/default-form-layout"))
const FloatingButton = (props) => {
  const { property } = props
  const url = typeof window !== "undefined" ? window.location.href : ""
  // Sticky scroll
  const [scroll, setScroll] = useState(false)
  const [pshow, setPShow] = useState(false)
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 450)
    })
    if (scroll) {
      document.body.classList.add("chat-with-button-scroll")
    } else {
      document.body.classList.remove("chat-with-button-scroll")
    }
  }, [scroll])
  // Sticky scroll

  const phandleModal = () => {
    setPShow(true)
  }

  const pcloseModal = () => {
    setPShow(false)
  }
  const [show, setShow] = useState(false)
  const handleModal = () => {
    setShow(true)
  }

  const closeModal = () => {
    setShow(false)
  }

  let team_data = useAllStrapiTeam()
  team_data = team_data.allStrapiTeam.nodes
  let team_key
  if (property?.negotiator_mapping != undefined) {
    for (const k in team_data) {
      if (property?.negotiator_mapping == team_data[k].negotiator_id) {
        team_key = k
        break
      }
    }
  }

  return (
    <div className="d-xl-none">
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => closeModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Apply for this job</h2>
            <DefaultForm
              fields={CareerFormFields}
              formtagclassname="team-form"
              // to_email_id={}
              pageTitle={`Careers- ${props?.title}`}
            />
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={pshow}
        onHide={() => setPShow(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="more-filters-modal team-form"
        id="filter-modal"
        // fullscreen={"xl-down"}
        backdrop="static"
      >
        <button
          className="cancel-button"
          type="button"
          onClick={() => pcloseModal()}
        >
          <i className="icon black-cancel" />
        </button>
        <Modal.Body>
          <div className="modal-body-container">
            <h2>Book your visit</h2>
            <DefaultForm
              fields={BookAViewingFields}
              formtagclassname="book-a-view-form"
              // to_email_id={props?.teamData?.email}
              prop_url={`${url}`}
              prop_address={`${props?.property?.display_address}`}
              prop_img_url={`${
                props?.property?.images && props?.property?.images?.strapi_json_value.length > 0
                  ? props?.property?.images?.strapi_json_value[0]?.url
                  : ""
              }`}
              email_template_type="book_a_viewing"
            />
          </div>
        </Modal.Body>
      </Modal>
      {scroll && (
        <section className={`floating-button-wrapper ${props.tag}`}>
          {props.tag === "property-details" && (
            <>
              <a
                href="javascript:;"
                onClick={() => phandleModal()}
                className="button button-skyblue button-with-icon"
              >
                <i className="icon icon-email-white" />
                Book your visit
              </a>
              {team_data[team_key]?.phone &&
              <a
                href={`tel: ${team_data[team_key]?.phone}`}
                className="button button-primary-light button-with-icon"
              >
                <i className="icon black-call-icon" />
                <span className=" d-md-inline d-none">Call</span>
              </a>}
              {team_data[team_key]?.whatsapp &&
              <a
                href={`${getWhatsAppURL(team_data[team_key]?.whatsapp)}`}
                className="button button-primary-light button-with-icon"
                target="_blank"
                rel="noreferrer"
              >
                <i className="icon  icon-phone-whatsapp-team" />
                <span className=" d-md-inline d-none">WhatsApp</span>
              </a>}
            </>
          )}

          {props.tag === "office-detail" && (
            <>
              <Link
                to={`/${PageLinks.enquiry}/`}
                className="button button-primary button-with-icon"
              >
                <i className="icon icon-envelope-white" />
                Enquire
              </Link>
              <a
                href={`tel: ${props.phone}`}
                className="button button-primary-light button-with-icon"
              >
                <i className="icon icon-phone-sticky" />
                Call
              </a>
              <Link
                to={`/${PageLinks.valuation}/`}
                className="button button-primary-light button-with-icon d-md-flex d-none"
              >
                <i className="icon  icon-arrow-up" />
                book a valuation
              </Link>
            </>
          )}
          {props.tag === "career-detail" && (
            <>
              <a
                // to={`/${PageLinks.send_cv}/`}
                href="javascript:void(0);"
                onClick={() => handleModal()}
                className="button button-skyblue button-with-icon"
              >
                <i className="icon icon-email-color" />
                Apply for this job
              </a>
              <a
                // to={`/${PageLinks.send_cv}/`}
                href={`tel:${props?.call}`}
                className="button button-primary-light button-with-icon"
              >
                <i className="icon black-call-icon" />
                <span className="d-none d-md-inline">call</span>
              </a>
            </>
          )}
        </section>
      )}
    </div>
  )
}

export default FloatingButton
