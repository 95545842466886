import loadable from "@loadable/component"
import { graphql } from "gatsby"
import _ from "lodash"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import sitelogoimage from "../images/logo.svg"

import { PageLinks } from "../common/site/page-static-links"
import FloatingButton from "../components/FloatingButton/FloatingButton"

const BreadcrumbModule = loadable(() =>
  import("../components/BreadcrumbModule/BreadcrumbModule")
)
const PropertyDetailBanner = loadable(() =>
  import("../components/PropertyDetailBanner/PropertyDetailBanner")
)
const PropertyDetailDesc = loadable(() =>
  import("../components/PropertyDetailDesc/PropertyDetailDesc")
)
const SimilarProperties = loadable(() =>
  import("../components/SimilarProperties/SimilarProperties")
)

const PropertyDetails = ({ data, children }) => {
  const property = data?.strapiProperty
  const globalContent =
  data?.allStrapiGlobalContent?.edges?.length > 0 &&
  data?.allStrapiGlobalContent?.edges[0]
  const companyphone = data?.site?.siteMetadata?.mailVars?.company_phone
  const officeDetailGlobal = data?.strapiOfficeDetail

  let processedImages = JSON.stringify({})
  if (property.imagetransforms?.images_Transforms) {
    processedImages = property?.imagetransforms?.images_Transforms
  }

  const propertyDetailImg = []
  for (let i = 0; i < property?.images?.strapi_json_value.length; i++) {
    if (property?.images?.strapi_json_value[i].url) {
      propertyDetailImg.push(property?.images?.strapi_json_value[i].url)
    }
  }

  // epc lightbox
  const propertyEpcImg = []
  for (let i = 0; i < property?.epc?.strapi_json_value.length; i++) {
    if (property?.epc?.strapi_json_value[i].url) {
      propertyEpcImg.push(property?.epc?.strapi_json_value[i].url)
    }
  }

  // floorplan lightbox
  const propertyFloorplanImg = []
  for (let i = 0; i < property?.floorplan?.strapi_json_value.length; i++) {
    if (property?.floorplan?.strapi_json_value[i].url) {
      propertyFloorplanImg.push(property?.floorplan?.strapi_json_value[i].url)
    }
  }

  const popularSearchDetailsObject = {
    // department: props.page_url_data.departmentVal,
    searchType: property.search_type,
    // action: property.search_type === "sales" ? "for-sale" : "for-rent",
    area: property.display_address,
    // postcode: props.page_url_data.areaVal,
    // bedrooms: props.page_url_data.bedVal,
    // propertyType: props.page_url_data.buildingval,
    // minprice: props.page_url_data.minpriceVal,
    // maxprice: props.page_url_data.maxpriceVal,
  }

  return (
    <Layout
      footerContact="footercontact"
      popularSearchDetailsObject={popularSearchDetailsObject}
      popularSearch="residential-detail"
    >
      <div className="layout-padding-top" />

      <BreadcrumbModule
        isproperty
        parentlabel={`${_.capitalize(property?.country)}`}
        parentlink={
          property.department === "residential" &&
          property.search_type === "sales"
            ? `${PageLinks.buy}/country-${property?.country}`
            : property.department === "residential" &&
              property.search_type === "lettings"
            ? `${PageLinks.rent}/country-${property?.country}`
            : property.department === "commercial" &&
              property.search_type === "sales"
            ? `${PageLinks.cbuy}/country-${property?.country}`
            : property.department === "commercial" &&
              property.search_type === "lettings"
            ? `${PageLinks.crent}/country-${property?.country}`
            : null
        }
        subparentlabel={`${_.capitalize(property?.address?.address3)}`}
        subparentlink={
          property.department === "residential" &&
          property.search_type === "sales"
            ? `${PageLinks.buy}/in-${property?.address?.address3
                ?.toLowerCase()
                ?.replace(/ /g, "-")}/country-${property?.country}`
            : property.department === "residential" &&
              property.search_type === "lettings"
            ? `${PageLinks.rent}/in-${property?.address?.address3
                ?.toLowerCase()
                ?.replace(/ /g, "-")}/country-${property?.country}`
            : property.department === "commercial" &&
              property.search_type === "sales"
            ? `${PageLinks.cbuy}/in-${property?.address?.address3
                ?.toLowerCase()
                ?.replace(/ /g, "-")}/country-${property?.country}`
            : property.department === "commercial" &&
              property.search_type === "lettings"
            ? `${PageLinks.crent}/in-${property?.address?.address3
                ?.toLowerCase()
                ?.replace(/ /g, "-")}/country-${property?.country}`
            : null
        }
        title={property.display_address}
      />

      <PropertyDetailBanner
        images={property?.images}
        processedImages={processedImages}
        strapi_id={property?.strapi_id}
        crm_id={property?.crm_id}
        propsImg={propertyDetailImg}
        propertyEpcImg={propertyEpcImg}
        propertyFloorplanImg={propertyFloorplanImg}
        videoTour={property?.video_tour}
        data={property}
      />

      <PropertyDetailDesc globalContent={globalContent} property={property} companyphone={companyphone} />

      <SimilarProperties
        prop_id={property?.strapi_id}
        tag="property-details"
        showDividerLine
        title={`Similar properties ${
          property.search_type == "sales" ? "for sale" : "for rent"
        }`}
      />

      <FloatingButton
        property={property}
        tag="property-details"
        phone={companyphone}
      />
    </Layout>
  )
}

export const query = graphql`
  query ($strapi_id: Int) {
    strapiProperty(strapi_id: { eq: $strapi_id }) {
      ...propertyFragment
    }
    allStrapiGlobalContent {
      edges {
        node {
          property_team_content {
            data {
              property_team_content
            }
          }
          property_team_title
        }
      }
    }
  }
`

export const Head = (props) => {
  // seo title, h1 and desc prepare, this can send to utilis function later
  const desc_meta_ptype =
    props.data.strapiProperty?.building?.strapi_json_value?.length > 0
      ? props.data.strapiProperty?.building?.strapi_json_value.join(" and ")
      : "property"

  const cap_ptype =
    desc_meta_ptype?.charAt(0).toUpperCase() + desc_meta_ptype?.slice(1)

  let desc_meta_seachtype = "for sale"
  if (props.data.strapiProperty?.search_type == "lettings") {
    desc_meta_seachtype = "for rent"
  }
  const desc_meta_beds = props.data.strapiProperty?.bedroom
  const desc_meta_address = props.data.strapiProperty?.display_address
  const desc_meta_price = `AED ${
    props.data.strapiProperty?.price?.toLocaleString()
  }`

  const pagetitle =
    props?.data?.strapiProperty?.department === "commercial"
      ? `Commercial ${cap_ptype} ${desc_meta_seachtype} in ${desc_meta_address}`
      : `${cap_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} ${desc_meta_beds > 1 ? "bedrooms" : "bedroom"} in ${desc_meta_address} at ${desc_meta_price}`

  const pagemetadesc =
    props?.data?.strapiProperty?.department === "commercial"
      ? `Book a viewing for this commercial ${cap_ptype} ${desc_meta_seachtype} in ${desc_meta_address}. Contact our property specialists for more information about the property.`
      : `Know the details of ${desc_meta_ptype} ${desc_meta_seachtype} with ${desc_meta_beds} ${desc_meta_beds > 1 ? "bedrooms" : "bedroom"} in ${desc_meta_address} at ${desc_meta_price}. Book a viewing with ${process.env.GATSBY_SITE_NAME} to get assistance in finding the right ${desc_meta_ptype} for you.`

  const pageUrl =
    process.env.GATSBY_SITE_URL +
    (props?.location?.pathname).replace(/\/?$/, "/")
  let myimgtransforms = ""
  if (props.data.strapiProperty?.imagetransforms?.images_Transforms) {
    myimgtransforms = JSON.parse(
      props.data.strapiProperty?.imagetransforms?.images_Transforms
    )
  }
  let pageImg = sitelogoimage
  if (myimgtransforms && Object.keys(myimgtransforms).length > 0) {
    const mymetaimg = Object.values(myimgtransforms)
    let pageImg_filt = []
    for (const myimgtransform in myimgtransforms) {
      if (typeof myimgtransforms[myimgtransform].webp !== "undefined") {
        pageImg_filt = Object.values(myimgtransforms[myimgtransform].webp)
        break
      }
    }
    if (pageImg_filt.length > 0) pageImg = pageImg_filt[0]
  }

  const curUrl = typeof window !== "undefined" ? window.location.href : ""

  const ldJson = {
    "@context": "https://schema.org",
    "@type": "Residence",
    address: props.data.strapiProperty?.display_address,
    description: pagemetadesc,
    name: props.data.strapiProperty?.slug.replace(/-/g, " "),
    photo: props.data.strapiProperty?.images?.strapi_json_value[0]?.url,
    mainEntityOfPage: {
			"@type": "ItemPage",
			"@id": curUrl
			}

  }
  return (
    <Seo title={pagetitle} description={pagemetadesc}>
      <meta name="image" content={pageImg} />
      <meta name="og:image" content={pageImg} />
      <meta name="og:image:width" content="400" />
      <meta name="og:image:height" content="300" />
      <meta name="twitter:image" content={pageImg} />
      <meta name="og:url" content={pageUrl} />
      <meta name="twitter:url" content={pageUrl} />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(ldJson) }}
      />
    </Seo>
  )
}

export default PropertyDetails
